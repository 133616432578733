import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
@Injectable({
  providedIn: 'root'
})
export class SalesInvoicePrintService {
  private dataSubject = new BehaviorSubject<any>(null);
  data$ = this.dataSubject.asObservable();
    http: any;
  constructor() { }

  setData(data: any) {
    this.dataSubject.next(data);
  }

  getComponentStyles(): Observable<string> {
    return this.http.get('./sales-invoice-print.component.css', { responseType: 'text' });
  }
}
