import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'numberToWords'
})
export class NumberToWordsPipe implements PipeTransform {

  private oneToTwenty = [
    'Zero', 'One', 'Two', 'Three', 'Four', 'Five', 'Six', 'Seven', 'Eight', 'Nine',
    'Ten', 'Eleven', 'Twelve', 'Thirteen', 'Fourteen', 'Fifteen', 'Sixteen', 'Seventeen', 'Eighteen', 'Nineteen'
  ];

  private tens = [
    '', '', 'Twenty', 'Thirty', 'Forty', 'Fifty', 'Sixty', 'Seventy', 'Eighty', 'Ninety'
  ];


  transform(value: number): string {
    if (value === 0) {
      return 'Zero';
    }

    return this.convertNumberToWords(value);

  }
  private convertNumberToWords(value: number): string {
    if (value < 20) {
      return this.oneToTwenty[value];
    }

    if (value < 100) {
      return this.tens[Math.floor(value / 10)] + (value % 10 !== 0 ? ' ' + this.oneToTwenty[value % 10] : '');
    }

    if (value < 1000) {
      return this.oneToTwenty[Math.floor(value / 100)] + ' Hundred' +
        (value % 100 !== 0 ? ' and ' + this.convertNumberToWords(value % 100) : '');
    }

    if (value < 10000) {
      return this.oneToTwenty[Math.floor(value / 1000)] + ' Thousand' +
        (value % 1000 !== 0 ? ' ' + this.convertNumberToWords(value % 1000) : '');
    }

    if (value < 1000000) {
      return this.convertNumberToWords(Math.floor(value / 1000)) + ' Thousand' +
        (value % 1000 !== 0 ? ' ' + this.convertNumberToWords(value % 1000) : '');
    }

    return 'Number out of range';
  }

}
