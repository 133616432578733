import { Component, ElementRef, Input, OnInit, ViewEncapsulation } from '@angular/core';
import { TemplateRef, OnDestroy, ViewChild, ChangeDetectionStrategy } from '@angular/core';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { SubSink } from 'subsink';
import { NumberToWordsPipe } from '../../../pipes/number-to-words.pipe';
//import * as numberToWords from 'number-to-words';

import { SalesInvoicePrintService } from './sales-invoice-print.service';
@Component({
  selector: 'app-sales-invoice-print',
  templateUrl: './sales-invoice-print.component.html',
  styleUrls: ['./sales-invoice-print.component.css'],
  encapsulation: ViewEncapsulation.None,
  providers: [NumberToWordsPipe]
})
export class SalesInvoicePrintComponent implements OnInit {
  //@Input() invoiceData: any;
  @ViewChild('grid', { static: false }) public gridElement: ElementRef | undefined;
  invoiceData: any
  gridData: any
  lineBreakArray: any = []
  FirstGrid: any
  SecGrid: any
  totalinwords: any;
  lengthoffirstarray: any
  LPONumber: any
  headerName:any
  constructor(public bsModalRef: BsModalRef, private modalService: SalesInvoicePrintService) { }

  ngOnInit(): void {
     
    this.modalService.data$.subscribe(data => {
      this.invoiceData = data;
      this.gridData =  data.Details
    });

    this.LPONumber = this.gridData[0].lpoId ? this.gridData[0].lpoId : ''
    this.headerName = this.invoiceData.TransType == 'Return Invoice' ? 'TAX CREDIT INVOICE' : 'TAX INVOICE';

    //for (let i = 0; i < this.gridData.length; i++) {
    //  this.lineBreakArray.push(i)
    //}
    //this.totalinwords = numberToWords.toWords(this.invoiceData.totalAmount);

    if (this.gridData.length > 20) {
      this.lineBreakArray.push(0)
      this.lineBreakArray.push(1)
    }
    else {
      this.lineBreakArray.push(0)
    }
    if (this.gridData.length > 20) {
      this.FirstGrid = this.gridData.slice(0, 20);
      this.SecGrid = this.gridData.slice(20);
    } else {
      this.FirstGrid = this.gridData;
      this.SecGrid = []; // Empty array for the remaining elements
    }
    this.lengthoffirstarray = this.FirstGrid.length

    console.log('first array', this.FirstGrid)
    console.log('sec' ,this.SecGrid)
   
  }

 
  //print() {
  //    if (this.gridElement) {
  //      const printWindow = window.open('', '_blank');
  //      printWindow?.document.write(this.gridElement.nativeElement.innerHTML);
  //      printWindow?.document.close();
  //      printWindow?.print();
  //    }

  //  }
  //print() {
  //  if (this.gridElement) {
  //    const printContent = this.gridElement.nativeElement.cloneNode(true) as HTMLElement;
  //    const printWindow = window.open('', '_blank');

  //    if (printWindow) {
  //      printWindow.document.write('<html><head><title>Print</title>');

  //      // Fetch and inject component styles
  //      this.modalService.getComponentStyles().subscribe(styles => {
  //        printWindow.document.write(`<style>${styles}</style>`);
  //        printWindow.document.write('</head><body>');
  //        printWindow.document.write(printContent.innerHTML);
  //        printWindow.document.write('</body></html>');
  //        printWindow.document.close();
  //        printWindow.print();
  //      });
  //    }
  //  }
  //}
  print() {
    if (this.gridElement) {
      const printWindow = window.open();
      const printDocument = printWindow?.document;

      // Write the HTML content to the new window
      printDocument?.write(this.gridElement.nativeElement.innerHTML);

      // Include a reference to your print styles within the new window
      printDocument?.write(`
      <style>
       .report-container {
  width: 21cm;
  height: 29.7cm;
  margin: 20px auto;
 
  box-sizing: border-box;
  text-align: center; /* Center content horizontally */
  position: relative;
  
}
.signature-label .capricorn-text {
    text-align: right;
    font-weight: bold;
    position: absolute;
    right: 12%;
    transform: translateX(50%);
  }
.report-container:last-child {
  page-break-after: auto;
}

.report-page-break{
    border :1px solid #000;
    box-sizing:border-box;
    position:relative;

}
.report-header {
  border-top: 1px solid #000;
  border-bottom: 1px solid #000;
  /*padding: 8px;*/
  margin-top: 150px;
  box-sizing: border-box;
}
:host {
  display: block;
  width: 100%;
}

.modal-content {
  max-height: calc(100vh - 100px); /* Adjust the value based on your needs */
  overflow-y: auto;
}
.container {
  display: flex;
  padding:5px;

}

/* Style for each div */
.box {
  flex: 1; /* Each div takes equal width */
  border: 1px solid #000; /* Solid black border */
  padding: 5px; /* Add padding for spacing */
  margin: 1px; /* Add margin for additional spacing */
  box-sizing: border-box; /* Include padding and border in total width */
  
}


  .box table {
    width: 100%;
    border-collapse: collapse; /* Collapse table borders */
    text-align:left;
    font-size:11px
  }

  /* Style for table cells (td) */
  .box td {
    
    padding: 1px; /* Add padding for spacing */
  }
.additional-table {
  
  box-sizing: border-box;
  padding:6px;
  font-size:8px;
  text-align:left;
}

  .additional-table table {
    width: 100%;
    border-collapse: collapse;
    border: 1px solid #000;
  }

  .additional-table td ,th{
  border-right: 1px solid #000;
    padding: 6px;
  }

  .additional-table th {
     border-bottom: 1px solid #000;
    padding: 2px;
    font-weight: bold;
  }


.additional-table-summary table {
  width: 100%;
  border-collapse: collapse;
  border: 1px solid #000; 
}

.additional-table-summary td
 {
  padding: 2px;

 }

 .additional-table-p {
  box-sizing: border-box;
  padding: 5px;
  font-size: 14px;
  text-align:left;
}

.additional-table-summary {

  box-sizing: border-box;
  padding: 5px;
  font-size:5px;
}

p {
  margin-top: 0;
  margin-bottom: 0rem;
}
.signature-label {
  
  padding: 5px;
  text-align: left;
  position:absolute;
  bottom:150px;
  width:100%;
  page-break-before: always;
  font-size:11px;
}
.report-container .pageBreak {
    page-break-after: always;
  }


.modal-footer {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;
  padding: 1rem;
  border-top: 0px solid #dee2e6;
  border-bottom-right-radius: 3px;
  border-bottom-left-radius: 3px;
}

        }

      </style>
    `);

      // Close the document stream
      printDocument?.close();

      // Trigger the print operation
      printWindow?.print();
    }
  }
}
